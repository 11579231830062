import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useAxiosAuthConfig } from '../utils/axios';

export interface IRegistrationData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  favoriteSchools: number[];
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface IUpdateFavorite {
  favoriteSchools: number[];
}

export interface IForgotPasswordData {
  email: string;
}

export interface IResendEmailConfirmation {
  email: string;
}

export interface IResetPasswordData {
  password: string;
  code: string;
}

export interface IIsEmailTaken {
  email: string;
}

export const useRegister = () =>
  useMutation({
    mutationFn: (registrationData: IRegistrationData) =>
      axios.post('/auth/local/register', {
        username: registrationData.email,
        email: registrationData.email,
        password: registrationData.password,
        firstname: registrationData.firstName,
        lastname: registrationData.lastName,
        favoriteSchools: registrationData.favoriteSchools,
      }),
  });

export const useLogin = () =>
  useMutation({
    mutationFn: (loginData: ILoginData) =>
      axios.post('/auth/local', {
        identifier: loginData.email,
        password: loginData.password,
      }),
  });

export const useFavoriteUpdate = () => {
  const config = useAxiosAuthConfig();

  return useMutation({
    mutationFn: ({ favoriteSchools }: IUpdateFavorite) =>
      axios.put(
        '/users/me',
        {
          favoriteSchools: favoriteSchools,
        },
        config
      ),
  });
};

export const useForgotPassword = () =>
  useMutation({
    mutationFn: (forgotPasswordData: IForgotPasswordData) =>
      axios.post('/auth/forgot-password', {
        email: forgotPasswordData.email,
      }),
  });

export const useResetPassword = () =>
  useMutation({
    mutationFn: (resetPasswordData: IResetPasswordData) =>
      axios.post('/auth/reset-password', {
        password: resetPasswordData.password,
        passwordConfirmation: resetPasswordData.password,
        code: resetPasswordData.code,
      }),
  });

export const useIsEmailTaken = () => {
  const qs = require('qs');
  const query = (email: string) =>
    qs.stringify(
      {
        filters: {
          email: {
            $eq: email,
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

  return useMutation({
    mutationFn: (isEmailTakenData: IIsEmailTaken) =>
      axios.get(`/users?${query(isEmailTakenData.email)}`).then((res) => {
        if (res.data) {
          return res.data?.length > 0;
        } else {
          return false;
        }
      }),
  });
};

export const useResendEmailConfirmation = () =>
  useMutation({
    mutationFn: ({ email }: IResendEmailConfirmation) =>
      axios.post('/auth/send-email-confirmation', {
        email: email,
      }),
  });
