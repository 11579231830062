import Step1 from './step-1.webp';
import Step2 from './step-2.webp';
import Step3 from './step-3.webp';
import Step4 from './step-4.webp';

import {
  CaretCircleRightIcon,
  DoneIcon,
} from '../../../../components/Icons/icons';
import {
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useValidableState,
  validateEmail,
  validatePassword,
} from '../../../../utils/validation';
import { useGetVoivodeships } from '../../../../api/voivodeship';
import { useGetCities } from '../../../../api/city';
import { useGetSchoolsByCity } from '../../../../api/school';
import Input from '../../../../components/Input/Input';
import { setFields } from '../../../../utils/object';
import { useIsEmailTaken, useRegister } from '../../../../api/auth';
import ButtonQuery from '../../../../query/ButtonQuery';
import Counter from '../../../../components/Input/Counter/Counter';
import { useNavigate } from 'react-router-dom';
import DropdownQuery from '../../../../query/Input/DropdownQuery';
import Switch from '../../../../components/Input/Switch/Switch';
import Button from '../../../../components/Button/Button';
import { ScaleY } from '../../../../components/Animations/PageTransitions';
import button from '../../../../components/Button/Button.module.scss';
import login from '../../../LoginPage/components/Login/Login.module.scss';
import globalColors from '../../../../styles/_colors.scss';
import s from './Register.module.scss';
import StepFour from './StepFour/StepFour';
import RegisterErrorPopup from '../../../../components/Popups/RegisterErrorPopup/RegisterErrorPopup';
import { motion } from 'framer-motion';

interface IProps {
  nextPage: (email: string) => void;
}

export interface ISchoolSetupData {
  voivodeship: string;
  city: string;
  school: string;
}

const Register = ({ nextPage }: IProps) => {
  const steps = 4;
  const navigate = useNavigate();

  const [registerError, setRegisterError] = useState(false);
  const [step, setStep] = useState(1);
  const [moreThanOnePlace, setMoreThanOnePlace] = useState(false);
  const [placeCounter, setPlaceCounter] = useState(1);
  const [schoolSetupIndex, setSchoolSetupIndex] = useState(0);

  const [email, setEmail, isEmailValid] = useValidableState<string>(
    '',
    validateEmail
  );
  const [repeatEmail, setRepeatEmail, isRepeatEmailValid] =
    useValidableState<string>('', (state) => email === state, [email]);
  const [firstName, setFirstName, isFirstNameValid] = useValidableState('');
  const [lastName, setLastName, isLastNameValid] = useValidableState('');
  const [password, setPassword, isPasswordValid] = useValidableState<string>(
    '',
    validatePassword
  );
  const [repeatPassword, setRepeatPassword, isRepeatPasswordValid] =
    useValidableState('', (state) => password === state, [password]);

  const [schoolSetupData, setSchoolSetupData] = useState<
    Array<ISchoolSetupData>
  >([
    {
      voivodeship: '',
      city: '',
      school: '',
    },
  ]);

  const setSchoolSetupDataField = useCallback(
    (index: number, field: keyof ISchoolSetupData) =>
      (value: SetStateAction<string>) => {
        setSchoolSetupData((prevState) => {
          const updatedState = [...prevState];
          let schoolSetup = updatedState[index];

          if (typeof value === 'string') {
            schoolSetup[field] = value;
          } else {
            schoolSetup[field] = value(schoolSetup[field]);
          }

          switch (field) {
            case 'voivodeship':
              schoolSetup = setFields(schoolSetup, ['city', 'school'], '');
              break;

            case 'city':
              schoolSetup = setFields(schoolSetup, ['school'], '');
              break;
          }

          updatedState[index] = schoolSetup;
          return updatedState;
        });
      },
    [setSchoolSetupData]
  );

  const isSchoolSetupDataValid = useCallback(
    (index: number, field: keyof ISchoolSetupData) =>
      schoolSetupData[index][field] !== '',
    [schoolSetupData]
  );

  const getSelectedSchools = useCallback(
    () =>
      schoolSetupData.reduce((acc: Array<string>, curr) => {
        acc.push(curr.school);
        return acc;
      }, []),
    [schoolSetupData]
  );

  const prevStep = useCallback(() => setStep(Math.max(1, step - 1)), [step]);

  const nextStep = useCallback(
    () => setStep(Math.min(step + 1, steps)),
    [step, steps]
  );

  const handleError = (axiosError: any, reset: () => void) => {
    const error: string = axiosError.response?.data?.error?.message;
    reset();

    if (error?.toLowerCase()?.includes?.('taken')) {
      console.error('Email taken');
    } else {
      setRegisterError(true);
      console.error(`Unknown register error: ${error}`);
    }
  };

  const isEmailTaken = useIsEmailTaken();
  const [emailTaken, setEmailTaken] = useState(false);

  const isForm1Valid = isEmailValid && !emailTaken;
  const isForm2Valid =
    isRepeatEmailValid &&
    isFirstNameValid &&
    isLastNameValid &&
    isPasswordValid &&
    isRepeatPasswordValid;
  const isSetupFirstSchool = schoolSetupIndex === 0;

  const isSetupLastSchool = schoolSetupIndex === schoolSetupData.length - 1;

  const voivodeships = useGetVoivodeships([1, 2]);
  const cities = useGetCities(
    schoolSetupData[schoolSetupIndex].voivodeship,
    [1, 2]
  );
  const schools = useGetSchoolsByCity(
    schoolSetupData[schoolSetupIndex].city,
    [1, 2]
  );
  const register = useRegister();

  useEffect(() => {
    if (placeCounter > schoolSetupData.length) {
      setSchoolSetupData((prevState) => [
        ...prevState,
        ...Array(placeCounter - schoolSetupData.length).fill({
          voivodeship: '',
          city: '',
          school: '',
        }),
      ]);
    } else if (placeCounter < schoolSetupData.length) {
      setSchoolSetupData((prevState) =>
        prevState.filter((_, index) => index < placeCounter)
      );
    }
  }, [placeCounter, setSchoolSetupData, schoolSetupData.length]);

  return (
    <>
      <RegisterErrorPopup
        visible={registerError}
        setVisible={setRegisterError}
      />
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
        className={s.wrapper}
      >
        <div className={s.steps}>
          {[...Array(steps)].map((_, i) => (
            <Fragment key={i}>
              <div className={`${s.step} ${i <= step - 1 ? s.active : ''}`}>
                {i < step - 1 ? (
                  <DoneIcon />
                ) : (
                  <p className='body-3'>{i + 1}</p>
                )}
              </div>

              {i !== steps - 1 && <div className={s.stepLine} />}
            </Fragment>
          ))}
        </div>

        <div className={`${login.container} ${s.stepWrapper}`}>
          {step === 1 && (
            <>
              <img src={Step1} alt='step-1' />
              <h2>4 kroki do posiadania konta</h2>
              <div className={login.form}>
                <Input
                  value={email}
                  setValue={(stateAction) => {
                    setEmailTaken(false);
                    setEmail(stateAction);
                  }}
                  errorMessage={
                    emailTaken ? 'Podany adres e-mail jest zajęty.' : ''
                  }
                  keyfilter='email'
                  placeholder='Adres email'
                  message='Podany adres e-mail będzie również loginem.'
                />
              </div>

              <div className={login.buttonsWrapper}>
                <div className={`${login.buttons}`}>
                  <Button
                    disabled={!isForm1Valid}
                    className={`${button.button} ${button.register} ${s.nextStepButton}`}
                    icon={<CaretCircleRightIcon />}
                    onClick={() =>
                      isEmailTaken.mutate(
                        { email },
                        {
                          onSuccess: (isTaken) => {
                            if (isTaken) {
                              setEmailTaken(true);
                            } else {
                              nextStep();
                            }
                          },
                          onError: (error) => {
                            console.error(error);
                          },
                        }
                      )
                    }
                  >
                    <p className='button-5'>Następny krok</p>
                  </Button>
                </div>

                <div className={login.buttons}>
                  <p className='button-3'>Posiadasz konto?</p>

                  <Button
                    onClick={() => navigate('/login')}
                    className={`${button.button} ${button.loginOutline}`}
                  >
                    <p className='button-5'>Zaloguj się</p>
                  </Button>
                </div>
              </div>
              <ScaleY color={globalColors.green1} />
            </>
          )}

          {step === 2 && (
            <>
              <img src={Step2} alt='step-2' />
              <h2>Podaj swoje dane</h2>
              <div className={s.formGrid}>
                <Input
                  disabled
                  value={email}
                  keyfilter='email'
                  placeholder='Adres email'
                />

                <Input
                  value={repeatEmail}
                  setValue={setRepeatEmail}
                  keyfilter='email'
                  placeholder='Powtórz adres email'
                  errorMessage={
                    isRepeatEmailValid ? '' : 'Podane e-maile się różnią.'
                  }
                />

                <Input
                  value={firstName}
                  setValue={setFirstName}
                  placeholder='Twoje imię'
                  errorMessage={isFirstNameValid ? '' : 'Podaj swoje imię.'}
                />

                <Input
                  value={lastName}
                  setValue={setLastName}
                  placeholder='Twoje nazwisko'
                  errorMessage={isLastNameValid ? '' : 'Podaj swoje nazwisko.'}
                />

                <Input
                  value={password}
                  setValue={setPassword}
                  password
                  placeholder='Hasło'
                  errorMessage={
                    isPasswordValid
                      ? ''
                      : 'Hasło musi zawierać 8-16 znaków, jedną wielką literę, cyfrę.'
                  }
                />

                <Input
                  value={repeatPassword}
                  setValue={setRepeatPassword}
                  password
                  placeholder='Powtórz hasło'
                  errorMessage={
                    isRepeatPasswordValid ? '' : 'Podane hasła się różnią.'
                  }
                />
              </div>

              <div className={`${login.buttonsWrapper} ${s.buttonsWrapper}`}>
                <div className={`${login.buttons}`}>
                  <Button
                    className={`${button.button} ${button.appointment} ${s.nextStepButton}`}
                    onClick={() => prevStep()}
                    icon={
                      <CaretCircleRightIcon
                        fill='#000'
                        style={{ transform: 'rotate(180deg)' }}
                      />
                    }
                  >
                    <p className='button-5'>Poprzedni krok</p>
                  </Button>

                  <Button
                    disabled={!isForm2Valid}
                    className={`${button.button} ${button.register} ${s.nextStepButton}`}
                    icon={<CaretCircleRightIcon />}
                    onClick={() => nextStep()}
                  >
                    <p className='button-5'>Następny krok</p>
                  </Button>
                </div>
              </div>
              <ScaleY color={globalColors.green1} />
            </>
          )}

          {step === 3 && (
            <>
              <img src={Step3} alt='step-3' />
              <h2>Wybierz szkołę Twojego dziecka:</h2>
              <div className={s.formGrid}>
                <DropdownQuery
                  inputClassName='body-3'
                  query={voivodeships}
                  value={schoolSetupData[schoolSetupIndex].voivodeship}
                  setValue={setSchoolSetupDataField(
                    schoolSetupIndex,
                    'voivodeship'
                  )}
                  placeholder='Wybierz województwo'
                />

                <DropdownQuery
                  disabled={
                    !isSchoolSetupDataValid(schoolSetupIndex, 'voivodeship')
                  }
                  inputClassName='body-3'
                  query={cities}
                  value={schoolSetupData[schoolSetupIndex].city}
                  setValue={setSchoolSetupDataField(schoolSetupIndex, 'city')}
                  placeholder='Wybierz miasto'
                />

                <DropdownQuery
                  disabled={!isSchoolSetupDataValid(schoolSetupIndex, 'city')}
                  inputClassName='body-3'
                  query={schools}
                  value={schoolSetupData[schoolSetupIndex].school}
                  setValue={setSchoolSetupDataField(schoolSetupIndex, 'school')}
                  placeholder='Wybierz szkołę'
                />
              </div>
              <div className={s.switch}>
                <Switch
                  disabled={!isSetupFirstSchool}
                  label={<p className='button-5'>Więcej niż jedna placówka</p>}
                  state={moreThanOnePlace}
                  setState={setMoreThanOnePlace}
                />

                {moreThanOnePlace && (
                  <Counter
                    disabled={!isSetupFirstSchool}
                    className={s.counter}
                    value={placeCounter}
                    setValue={setPlaceCounter}
                    min={1}
                    inputClassName='button-3'
                  />
                )}
              </div>

              <div className={`${login.buttonsWrapper} ${s.buttonsWrapper}`}>
                <div className={`${login.buttons}`}>
                  <Button
                    className={`${button.button} ${button.appointment} ${s.nextStepButton}`}
                    onClick={() => prevStep()}
                    icon={
                      <CaretCircleRightIcon
                        fill='#000'
                        style={{ transform: 'rotate(180deg)' }}
                      />
                    }
                  >
                    <p className='button-5'>Poprzedni krok</p>
                  </Button>

                  <Button
                    disabled={
                      !isSchoolSetupDataValid(schoolSetupIndex, 'school')
                    }
                    className={`${button.button} ${button.register} ${s.nextStepButton}`}
                    icon={<CaretCircleRightIcon />}
                    onClick={() => {
                      if (isSetupLastSchool) {
                        nextStep();
                        setSchoolSetupIndex(0);
                      } else {
                        setSchoolSetupIndex(schoolSetupIndex + 1);
                      }
                    }}
                  >
                    <p className='button-5'>
                      {isSetupLastSchool
                        ? 'Następny krok'
                        : 'Dodaj następną szkołę'}
                    </p>
                  </Button>
                </div>
              </div>
              <ScaleY color={globalColors.green1} />
            </>
          )}

          {step === 4 && (
            <>
              <img src={Step4} alt='step-4' />
              <h2>Sprawdź wszystkie dane</h2>
              <div className={s.formGrid}>
                <h4 style={{ gridColumn: 'span 2' }}>Dane personalne</h4>

                <Input disabled value={firstName} placeholder='Twoje imię' />

                <Input disabled value={lastName} placeholder='Twoje nazwisko' />

                <Input
                  disabled
                  value={email}
                  keyfilter='email'
                  placeholder='Adres email'
                />

                <Input disabled value={password} password placeholder='Hasło' />
              </div>

              {schoolSetupData.map((schoolSetup, index) => {
                return (
                  <StepFour
                    key={index}
                    schoolSetup={schoolSetup}
                    index={index}
                  />
                );
              })}

              <div className={s.buttons}>
                <Button
                  className={`${button.button} ${button.appointment} ${s.nextStepButton}`}
                  onClick={() => prevStep()}
                  icon={
                    <CaretCircleRightIcon
                      fill='#000'
                      style={{ transform: 'rotate(180deg)' }}
                    />
                  }
                >
                  <p className='button-5'>Poprzedni krok</p>
                </Button>

                <ButtonQuery
                  style={{ width: '212px', height: '36px' }}
                  className={`${button.button} ${button.register}`}
                  icon={<CaretCircleRightIcon />}
                  mutation={register}
                  mutationParam={{
                    email,
                    password,
                    firstName,
                    lastName,
                    favoriteSchools: getSelectedSchools(),
                  }}
                  onMutationError={handleError}
                  onMutationSuccess={() => {
                    nextPage(email);
                  }}
                >
                  <p className='button-5'>Zakładam konto</p>
                </ButtonQuery>
              </div>
              <ScaleY color={globalColors.green1} />
            </>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default Register;
