import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { mapArrayToObject } from '../utils/array';

export const useGetGrades = (school: string) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        school: {
          id: {
            $eq: school,
          },
        },
      },
      populate: {
        grade: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['grades', school],
    queryFn: () => {
      return axios.get(`/works?${query}`).then((res) => {
        return mapArrayToObject(
          res.data?.data,
          (obj: any) => obj.grade.id,
          (obj: any) => obj.grade.name
        );
      });
    },
  });
};
