export const formatDate = (date: string | number) => {
  const d = new Date(date);

  return `${String(d.getDate()).padStart(2, '0')}.${String(
    d.getMonth() + 1
  ).padStart(2, '0')}.${d.getFullYear()}`;
};

export const yearBook = (date: string) => {
  const month = new Date(date).getMonth() + 1;
  let startingYear: number = 0;

  switch (true) {
    case month >= 9:
      startingYear = new Date(date).getFullYear();
      break;
    case month >= 1 && month <= 8:
      startingYear = new Date(date).getFullYear() - 1;
      break;
  }

  const yearBook =
    startingYear.toString() + '/' + (startingYear + 1).toString();

  return yearBook;
};
