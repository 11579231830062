import { motion } from 'framer-motion';
import s from './Filters.module.scss';
import card from '../../../../components/Card/FlexCard.module.scss';
import DropdownQuery from '../../../../query/Input/DropdownQuery';
import { setFields } from '../../../../utils/object';
import { SetStateAction, useCallback, useState } from 'react';
import { useGetVoivodeships } from '../../../../api/voivodeship';
import { useGetCities } from '../../../../api/city';
import { useGetSchools, useGetSchoolsByCity } from '../../../../api/school';
import { useGetGrades } from '../../../../api/grade';
import { useEffect } from 'react';
import { scrollToId } from '../../../../utils/scroll';

interface ISchoolSetupData {
  voivodeship: string;
  city: string;
  school: string;
  grade: string;
}

const Filters = (props: any) => {
  const [schoolSetupData, setSchoolSetupData] = useState<
    Array<ISchoolSetupData>
  >([
    {
      voivodeship: '',
      city: '',
      school: '',
      grade: '',
    },
  ]);

  const setSchoolSetupDataField = useCallback(
    (index: number, field: keyof ISchoolSetupData) =>
      (value: SetStateAction<string>) => {
        setSchoolSetupData((prevState) => {
          const updatedState = [...prevState];
          let schoolSetup = updatedState[index];

          if (typeof value === 'string') {
            schoolSetup[field] = value;
          } else {
            schoolSetup[field] = value(schoolSetup[field]);
          }

          switch (field) {
            case 'voivodeship':
              schoolSetup = setFields(schoolSetup, ['city', 'school'], '');
              break;

            case 'city':
              schoolSetup = setFields(schoolSetup, ['school'], '');
              break;
            case 'school':
              schoolSetup = setFields(schoolSetup, ['grade'], '');
              break;
          }

          updatedState[index] = schoolSetup;
          return updatedState;
        });
      },
    [setSchoolSetupData]
  );
  const [schoolSetupIndex] = useState(0);
  const voivodeships = useGetVoivodeships(props.schoolType);
  const cities = useGetCities(
    schoolSetupData[schoolSetupIndex].voivodeship,
    props.schoolType
  );
  const schools = useGetSchoolsByCity(
    schoolSetupData[schoolSetupIndex].city,
    props.schoolType
  );
  const grades = useGetGrades(props.school?.[0]?.id);
  const isSchoolSetupDataValid = useCallback(
    (index: number, field: keyof ISchoolSetupData) =>
      schoolSetupData[index][field] !== '',
    [schoolSetupData]
  );

  const callbackData: any = useGetSchools(
    schoolSetupData[schoolSetupIndex].voivodeship,
    schoolSetupData[schoolSetupIndex].city,
    schoolSetupData[schoolSetupIndex].school,
    props.schoolType
  );

  useEffect(() => {
    if (props.callback) {
      const { data } = callbackData;
      if (data?.length >= 0) {
        props.callback(data);
      }
    }
  }, [callbackData, props]);

  return (
    <div className={s.filters}>
      <div className={`${card.allCards} ${s.filtersWrapper}`}>
        <div className={`${card.singleCard} ${s.filterWrapper}`}>
          <motion.h2 className='headline-2'>Intuicyjne</motion.h2>
          <DropdownQuery
            disabled={!!props.school}
            inputClassName='body-3'
            query={voivodeships}
            value={schoolSetupData[schoolSetupIndex].voivodeship}
            setValue={setSchoolSetupDataField(schoolSetupIndex, 'voivodeship')}
            placeholder={
              props?.school
                ? props?.school?.[0]?.voivodeship?.name
                : 'Wybierz województwo'
            }
          />
        </div>
        <div className={`${card.singleCard} ${s.filterWrapper}`}>
          <motion.h2 className='headline-2'>Filtrowanie</motion.h2>
          <DropdownQuery
            disabled={!isSchoolSetupDataValid(schoolSetupIndex, 'voivodeship')}
            inputClassName='body-3'
            query={cities}
            value={schoolSetupData[schoolSetupIndex].city}
            setValue={setSchoolSetupDataField(schoolSetupIndex, 'city')}
            placeholder={
              props?.school ? props?.school?.[0]?.city?.name : 'Wybierz miasto'
            }
          />
        </div>
        <div className={`${card.singleCard} ${s.filterWrapper}`}>
          <motion.h2 className='headline-2'>
            {props.schoolFilterTitle}
          </motion.h2>
          <DropdownQuery
            disabled={!isSchoolSetupDataValid(schoolSetupIndex, 'city')}
            inputClassName='body-3'
            query={schools}
            value={schoolSetupData[schoolSetupIndex].school}
            setValue={setSchoolSetupDataField(schoolSetupIndex, 'school')}
            onChange={() => {
              if (props.shortView) {
                scrollToId('schools-cards', 100, 'start');
              }
            }}
            placeholder={
              props?.school ? props?.school?.[0]?.name : 'Wybierz szkołę'
            }
          />
        </div>
        {!props.shortView && (
          <div className={`${card.singleCard} ${s.filterWrapper}`}>
            <motion.h2 className='headline-2'>
              {props.gradeFilterTitle}
            </motion.h2>
            <DropdownQuery
              disabled={
                props?.school
                  ? false
                  : !isSchoolSetupDataValid(schoolSetupIndex, 'school')
              }
              inputClassName='body-3'
              query={grades}
              value={schoolSetupData[schoolSetupIndex].grade}
              setValue={setSchoolSetupDataField(schoolSetupIndex, 'grade')}
              placeholder='Wybierz klasę'
              onChange={() => {
                if (props.callbackAlt) {
                  props.callbackAlt(schoolSetupData[schoolSetupIndex].grade);
                }
                scrollToId('schools-cards', 100, 'start');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
