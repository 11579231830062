import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import SocialMedia from '../../../../components/SocialMedia/SocialMedia';
import SubHeader from '../../../../components/SubHeader/SubHeader';
import Banner from '../../components/Banner/Banner';
import s from '../../SchoolPages.module.scss';
import BannerImage from './components/choose-grade.webp';
import Filters from '../../components/Filters/Filters';
import BannerImg from './components/data-banner-grades.webp';
import Cards from '../../components/Cards/Cards';
import cardRegular from './components/grades-book.webp';
import cardWinner from './components/1stPlaceGrades.webp';
import globalColors from '../../../../styles/_colors.scss';
import { useGetWorks } from '../../../../api/work';
import { useEffect, useState } from 'react';
import { useGetSchoolById } from '../../../../api/school';
import { calculateClassAttendance } from '../../components/utils';
import { yearBook } from '../../../../utils/datetime';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
import { useSchoolParams } from '../../../../utils/api/school';
import IntroMessage from '../../components/IntroMessage/IntroMessage';
import globals from '../../../../App.scss';
import DataBannerAlt from '../../components/DataBannerAlt/DataBannerAlt';

const GradesPage = () => {
  const params = useSchoolParams();
  const works = useGetWorks({ school: params.schoolId });
  const school = useGetSchoolById(params.schoolId);
  const [resWorks, setResWorks] = useState<any>(null);
  const [resSchool, setResSchool] = useState<any>(null);
  const [grades, setGrades] = useState<any>(null);
  const mobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const { data } = school;
    if (data?.length >= 0) {
      setResSchool(data);
    }
  }, [school]);

  useEffect(() => {
    const { data } = works;
    if (data?.length >= 0) {
      setResWorks(data);
    }
  }, [works]);

  const filterGrades = (gradeId: number) => {
    if (gradeId) {
      let grades: any = [];
      resWorks?.forEach((work: any) => {
        grades.push({
          id: work.grade.id,
          name:
            'Klasa ' + work.grade.name + ' rocz.' + yearBook(work.createdAt),
          address: resSchool?.[0]?.name,
          participatingGrades: work.votes.length ?? 0,
        });
      });
      let uniqueGrades = [
        ...new Map(grades.map((item: any) => [item['name'], item])).values(),
      ];
      const finalGrades = uniqueGrades?.filter((item: any) => {
        return +gradeId === item.id;
      });
      setGrades(finalGrades);
    }
  };

  useEffect(() => {
    let grades: any = [];
    resWorks?.forEach((work: any) => {
      grades.push({
        id: work.grade.id,
        name: 'Klasa ' + work.grade.name + ' rocz.' + yearBook(work.createdAt),
        address: resSchool?.[0]?.name,
        participatingGrades: work.votes.length ?? 0,
      });
    });
    let uniqueGrades = [
      ...new Map(grades.map((item: any) => [item['name'], item])).values(),
    ];
    setGrades(uniqueGrades);
  }, [resWorks, resSchool]);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <SubHeader color={globalColors.green1} />
      <BreadCrumbs grades={resSchool?.[0]?.name} />
      <Banner img={BannerImage} headline='Wybierz klasę' />
      <div className={s.messageAndSearchbar}>
        <IntroMessage />
        <SearchBar
          style={{
            width: mobile ? globals.mobileWidth : '30%',
            minWidth: '270px',
          }}
        />
      </div>
      <DataBannerAlt
        school={resSchool?.[0]}
        classAttendance={calculateClassAttendance({
          numerator: resSchool?.[0]?.participatingGrades,
          denominator: resSchool?.[0]?.gradesTotal,
        })}
        img={BannerImg}
        className='gradient-elementary-school-2'
      />
      {/* schooltype 2 is elementary school in backend */}
      <Filters
        school={resSchool}
        callbackAlt={filterGrades}
        schoolType={2}
        schoolFilterTitle='Szkoły'
        gradeFilterTitle='Klasy'
      />
      <Cards
        cardData={grades}
        cardWinner={cardWinner}
        cardRegular={cardRegular}
        cardWinnerImgStyle={{ width: '183px', height: '200px' }}
        cardAvatarImgStyle={{ width: '131px', height: '134px' }}
        color={globalColors.blue4}
        actionButtonText='Wybierz klasę'
        additionalText='Liczba polubień:'
        noFavorites={true}
        showResultsSummary={true}
        showResultsText='Liczba klas biorących udział w lekcjach Pana Tableta:'
      />
      <SocialMedia />
    </motion.div>
  );
};

export default GradesPage;
