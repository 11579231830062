import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ArrowDownIcon, CheckmarkIcon } from '../../Icons/icons';
import { useOnClickOutside } from 'usehooks-ts';
import input from '../Input.module.scss';
import s from './Dropdown.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

export interface Options {
  [key: string]: string;
}

export interface IProps {
  placeholder?: string;
  options?: Options;
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  inputClassName?: string;
  disabled?: boolean;
  onChange?: any;
}

const Dropdown = ({
  placeholder,
  options,
  value,
  setValue,
  inputClassName,
  disabled,
  onChange = () => {},
}: IProps) => {
  const ref = useRef(null);
  const [opened, setOpenedOrig] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any>();
  const [dropdownInput, setDropdownInput] = useState<string | null>('');
  const filterOptions = useCallback(
    (inputDropdown: string | null) => {
      let obj: any = {};
      if (options && inputDropdown) {
        for (const property in options) {
          if (
            options[property]
              .toLowerCase()
              .includes(inputDropdown.toLowerCase())
          ) {
            obj[property] = options[property];
          }
        }
        setFilteredOptions(obj);
      } else if (options) {
        setFilteredOptions(options);
      }
    },
    [options]
  );
  const setOpened: Dispatch<SetStateAction<boolean>> = useCallback(
    (stateAction) => {
      let newState;

      if (typeof stateAction === 'boolean') {
        newState = stateAction;
      } else {
        newState = stateAction(opened);
      }

      if (options?.[value]) {
        if (!newState) {
          setDropdownInput(null);
          filterOptions(null);
        } else {
          setDropdownInput('');
        }
      }

      setOpenedOrig(stateAction);
    },
    [filterOptions, opened, options, value]
  );
  const toggle = useCallback(() => {
    if (!disabled) {
      setOpened(!opened);
    }
  }, [disabled, opened, setOpened]);
  const select = useCallback(
    (key: string) => {
      if (setValue) {
        setValue(key);
      }
      setDropdownInput(null);
      setOpened(false);
    },
    [setValue, setOpened]
  );
  useEffect(() => {
    if (!options?.[value]) {
      setDropdownInput('');
    } else {
      setDropdownInput(null);
    }
  }, [options, value]);
  useOnClickOutside(ref, () => setOpened(false));

  useEffect(() => {
    filterOptions(dropdownInput);
  }, [dropdownInput, filterOptions]);

  return (
    <div ref={ref} className={s.container}>
      <div
        className={`${input.input} ${input.inputContainer} ${s.dropdown} ${
          disabled ? input.disabled : ''
        }`}
      >
        <input
          value={dropdownInput ?? options?.[value] ?? ''}
          onChange={(event) => {
            setDropdownInput(event.target.value);
          }}
          onClick={() => {
            if (!opened) {
              setOpened(true);
            }
          }}
          placeholder={placeholder}
          className={inputClassName}
          disabled={disabled}
        ></input>
        <ArrowDownIcon
          onClick={() => toggle()}
          className={s.dropdownArrow}
          style={{ transform: opened ? 'rotate(180deg)' : '' }}
        />
      </div>
      <AnimatePresence initial={false}>
        {opened && (
          <motion.section
            className={`${input.inputContainer} ${s.list}`}
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              style={{ padding: '2.4rem 1.3rem' }}
              variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
              transition={{ duration: 0.8 }}
            >
              {filteredOptions &&
                Object.entries(filteredOptions).sort((a: any, b: any) => a[1].localeCompare(b[1])).map(([k, v]: any, _) => {
                  return (
                    <div
                      onClick={() => {
                        select(k);
                        onChange();
                      }}
                      className={`${s.item} ${value === k ? s.selected : ''}`}
                      key={k}
                    >
                      <p className={inputClassName}>{v}</p>

                      {value === k && <CheckmarkIcon />}
                    </div>
                  );
                })}

              {Object.keys(filteredOptions).length === 0 && (
                <div className={s.item}>
                  <p className={inputClassName}>Brak wyników</p>
                </div>
              )}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;
