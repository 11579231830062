import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { EyeIcon } from '../Icons/icons';
import { InputText } from 'primereact/inputtext';
import { KeyFilterType } from 'primereact/keyfilter';
import { withMessage } from '../../utils/hoc/hoc';
import s from './Input.module.scss';

export enum IInputTheme {
  Transparent,
}

interface IProps {
  keyfilter?: KeyFilterType;
  icon?: ReactElement;
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  floatingPlaceholder?: string;
  placeholder?: string;
  disabled?: boolean;
  password?: boolean;
  error?: boolean;
  className?: string;
  theme?: IInputTheme;
}

const Input = ({
  keyfilter,
  icon,
  value,
  setValue,
  floatingPlaceholder,
  placeholder,
  disabled,
  password,
  error,
  className,
  theme,
}: IProps) => {
  const [focused, setFocused] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(!password);
  const showFloatingLabel = value?.length > 0 || focused;

  const getThemeClassName = useCallback(() => {
    switch (theme) {
      case IInputTheme.Transparent:
        return s.styleTransparent;

      default:
        return '';
    }
  }, [theme]);

  return (
    <div
      className={`${s.wrapper} ${
        s.inputContainer
      } ${getThemeClassName()} ${className} ${disabled ? s.disabled : ''} ${
        error ? s.error : focused ? s.focus : ''
      }`}
      style={{ height: '54px' }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        {showFloatingLabel && (
          <div
            className={`${s.floatingLabel} ${getThemeClassName()} ${
              disabled ? s.disabled : ''
            }`}
          >
            <p className='button-3'>
              {floatingPlaceholder ? floatingPlaceholder : placeholder}
            </p>
          </div>
        )}

        <InputText
          keyfilter={keyfilter}
          value={value}
          onChange={(e) => {
            if (setValue) {
              setValue(e.target.value);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          type={passwordVisible ? 'text' : 'password'}
          disabled={disabled}
          placeholder={showFloatingLabel ? '' : placeholder}
          className={`${s.input} ${getThemeClassName()}`}
        />
      </div>

      {icon ? (
        <div className={s.toggle}>{icon}</div>
      ) : (
        password && (
          <div
            onClick={() => setPasswordVisible(!passwordVisible)}
            className={s.toggle}
          >
            <EyeIcon />
          </div>
        )
      )}
    </div>
  );
};

export default withMessage(Input);
