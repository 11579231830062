import { Hamburger } from '../Hamburger/Hamburger';
import s from './MobileHeader.module.scss';
import pageBar from '../PageBar/PageBar.module.scss';
import { useNavigate } from 'react-router-dom';
import { LoginIcon } from '../Icons/icons';
import { LogoutIcon } from '../Icons/icons';
import Logo from '../PageBar/logo.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOut } from '../../redux/slices/userSlice';
import { useCycle } from 'framer-motion';

const MobileHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const userData = useAppSelector((state) => state.user.userData);
  const [isOpen, toggleOpen] = useCycle(false, true);
  return (
    <header
      style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
      className={`${pageBar.bar} ${s.mobileHeader}`}
    >
      <Hamburger isOpen={isOpen} toggleOpen={toggleOpen} />
      <img
        className={s.logo}
        onClick={() => {
          if (isOpen) {
            toggleOpen();
          }
          navigate('/');
        }}
        src={Logo}
        alt='logo'
      />
      {loggedIn ? (
        <div className={s.logout}>
          <p className='field-1'>{'Cześć, ' + userData?.firstName}</p>
          <LogoutIcon onClick={() => dispatch(logOut())} />
        </div>
      ) : (
        <div className={s.login}>
          <LoginIcon
            onClick={() => {
              if (isOpen) {
                toggleOpen();
              }
              navigate('/pre-login');
            }}
          />
        </div>
      )}
    </header>
  );
};
export default MobileHeader;
