import { useRef, useState } from 'react';
import s from './SearchBar.module.scss';
import { useOnClickOutside } from 'usehooks-ts';
import { MagnifierIcon } from '../Icons/icons';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useGetSchoolsForSearchbar } from '../../api/school';
import { useLocation } from '../../utils/location';
import { scrollToId } from '../../utils/scroll';

interface IProps {
  className?: string;
  style?: object;
}

const SearchBar = ({ className, style }: IProps) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState('');

  useOnClickOutside(ref, () => setFocused(false));

  const { data: schools } = useGetSchoolsForSearchbar(value);

  const onClickNavigate = (item: any) => {
    const schoolType = item.schoolType.id === 1 ? 'kindergarten' : 'elementary';
    if (location.pathname.startsWith('/' + schoolType)) {
      location.push('/' + schoolType + '/' + item.id + '#schools-cards');
      scrollToId('schools-cards', 100, 'start');
    } else {
      navigate('/' + schoolType + '/' + item.id + '#schools-cards');
    }
    setFocused(false);
  };

  return (
    <div style={style} className={`${s.container} ${className}`}>
      <div
        ref={ref}
        className={`${s.wrapper} ${s.defaultStyle} ${
          focused ? 'shadow-1' : ''
        }`}
      >
        <motion.div
          transition={{
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 1,
            delay: 0,
          }}
          animate={{
            y: 5,
          }}
          className={s.leftIcon}
        >
          <MagnifierIcon />
        </motion.div>
        <div className={s.inputContainer}>
          <input
            className={`${s.searchBar} body-4`}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            type='text'
            placeholder={'Znajdź szkołę swojego dziecka'}
            onClick={() => setFocused(true)}
          />
        </div>
        {focused && (
          <div className={s.dropdown}>
            {schools.slice(0, 5).map((item: any, index: number) => (
              <div key={item.id} className={s.dropdownItem}>
                <p
                  onClick={() => onClickNavigate(item)}
                  className={`small ${s.record}`}
                >
                  {item.name}
                </p>
              </div>
            ))}
            <p className={`small`}>{schools.length} wynik/ów</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchBar;
