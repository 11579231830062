import 'primereact/resources/primereact.min.css';
import './App.scss';
import './pages/SchoolPages/components/WorkGallery/components/ReactImageGallery/ReactImageGallery.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import IndexPage from './pages/IndexPage';
import RegisterPage from './pages/RegisterPage';
import MobileHeader from './components/Header/MobileHeader';
import PreLogin from './pages/LoginPage/components/PreLogin/PreLogin';
import Login from './pages/LoginPage/components/Login/Login';
import PreRegister from './pages/RegisterPage/components/PreRegister/PreRegister';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { useLocation, useRoutes } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { useUserSession } from './utils/session';
import SchoolPage from './pages/SchoolPages/Elementary/Elementary';
import KindergartenPage from './pages/SchoolPages/Kindergarten/Kindergarten';
import GradesPage from './pages/SchoolPages/Elementary/GradesPage/GradesPage';
import GroupsPage from './pages/SchoolPages/Kindergarten/GroupsPage/GroupsPage';
import GradePage from './pages/SchoolPages/Elementary/GradesPage/GradePage/GradePage';
import GroupPage from './pages/SchoolPages/Kindergarten/GroupsPage/GroupPage/GroupPage';
import ContestsPage from './pages/ContestsPage/ContestsPage';
import ElementaryContestsListPage from './pages/ContestsListPage/ElementaryContestsListPage';
import KindergartenContestsListPage from './pages/ContestsListPage/KindergartenContestsListPage';
import GroupWorkPage from './pages/SchoolPages/Kindergarten/GroupsPage/GroupPage/GroupWorkPage/GroupWorkPage';
import GradeWorkPage from './pages/SchoolPages/Elementary/GradesPage/GradePage/GradeWorkPage/GradeWorkPage';
import ElementaryContestPage from './pages/ContestsListPage/ElementaryContestPage';
import KindergartenContestPage from './pages/ContestsListPage/KindergartenContestPage';
import ElementaryContestsListFinishedPage from './pages/ContestsListPage/ElementaryContestsListFinishedPage';
import KindergartenContestsListFinishedPage from './pages/ContestsListPage/KindergartenContestsListFinishedPage';
import { useScrollbarWidthCSS } from './utils/scroll';
import { AnimatePresence } from 'framer-motion';
import { cloneElement } from 'react';
import MrTabletCookieConsent from './components/CookieConsent/CookieConsent';

const App = () => {
  useUserSession();
  useScrollbarWidthCSS();
  const mobile = useMediaQuery('(max-width: 768px)');
  const overMaxWidth = !useMediaQuery('(max-width: 1512px)');

  const element = useRoutes([
    {
      path: '/',
      element: <IndexPage />,
    },
    {
      path: '/pre-login',
      element: <PreLogin />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/pre-register',
      element: <PreRegister />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: '/elementary',
      element: <SchoolPage />,
    },
    {
      path: '/elementary/:schoolId',
      element: <GradesPage />,
    },
    {
      path: '/elementary/:schoolId/:gradeId',
      element: <GradePage />,
    },
    {
      path: '/elementary/:schoolId/:gradeId/:workId',
      element: <GradeWorkPage />,
    },
    {
      path: '/kindergarten',
      element: <KindergartenPage />,
    },
    {
      path: '/kindergarten/:kindergartenId',
      element: <GroupsPage />,
    },
    {
      path: '/kindergarten/:kindergartenId/:groupId',
      element: <GroupPage />,
    },
    {
      path: '/kindergarten/:kindergartenId/:groupId/:workId',
      element: <GroupWorkPage />,
    },
    {
      path: '/contests',
      element: <ContestsPage />,
    },
    {
      path: '/contests/elementary/',
      element: <ElementaryContestsListPage />,
    },
    {
      path: '/contests/kindergarten/',
      element: <KindergartenContestsListPage />,
    },
    {
      path: '/contests/elementary/:contestId',
      element: <ElementaryContestPage />,
    },
    {
      path: '/contests/kindergarten/:contestId',
      element: <KindergartenContestPage />,
    },
    {
      path: '/contests/elementary/:contestId/:schoolId/:gradeId',
      element: <ElementaryContestPage />,
    },
    {
      path: '/contests/kindergarten/:contestId/:schoolId/:gradeId',
      element: <KindergartenContestPage />,
    },
    {
      path: '/contests/elementary/:contestId/:schoolId/:gradeId/:workId',
      element: <ElementaryContestPage />,
    },
    {
      path: '/contests/kindergarten/:contestId/:schoolId/:gradeId/:workId',
      element: <KindergartenContestPage />,
    },
    {
      path: '/contests/elementary/finished',
      element: <ElementaryContestsListFinishedPage />,
    },
    {
      path: '/contests/kindergarten/finished',
      element: <KindergartenContestsListFinishedPage />,
    },
  ]);

  const location = useLocation();

  if (!element) return null;

  const header = mobile ? <MobileHeader /> : <Header />;

  return (
    <AnimatePresence mode='wait'>
      {cloneElement(header, { key: location.pathname + 'header' })}
      {cloneElement(
        <div style={overMaxWidth ? {} : { overflowX: 'hidden' }}>
          {element}
        </div>,
        {
          key: location.pathname,
        }
      )}
      {cloneElement(<Footer />, { key: location.pathname + 'footer' })}
      <MrTabletCookieConsent />
    </AnimatePresence>
  );
};

export default App;
