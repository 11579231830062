import SubHeader from '../../../../../components/SubHeader/SubHeader';
import globalColors from '../../../../../styles/_colors.scss';
import s from '../../../SchoolPages.module.scss';
import BreadCrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';
import IntroMessage from '../../../components/IntroMessageAlt/IntroMessageAlt';
import BrandHero from './components/brand-hero-kindergarten.webp';
import Media from './components/chart-placeholder.webp';
import { Divider } from '../../../../../components/Divider/Divider';
import Contest from '../../../components/Contest/Contest';
import ChildrenWorks from '../../../components/ChildrenWorks/ChildrenWorks';
import { useGetWorks } from '../../../../../api/work';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../redux/hooks';
import SocialMedia from '../../../../../components/SocialMedia/SocialMedia';
import { motion } from 'framer-motion';
import { useSchoolParams } from '../../../../../utils/api/school';
import { useMediaQuery } from 'usehooks-ts';

const GroupPage = () => {
  const params = useSchoolParams();
  const works = useGetWorks({
    school: params.schoolId,
    grade: params.gradeId,
  });
  const [resWorks, setResWorks] = useState<any>(null);
  useEffect(() => {
    const { data } = works;
    if (data?.length >= 0) {
      setResWorks(data);
    }
  }, [works]);
  const user = useAppSelector((state) => state.user);
  const loggedIn = !!user.userData;

  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <SubHeader color={globalColors.blue4} />
      <BreadCrumbs
        groups={resWorks?.[0]?.school?.name}
        group={resWorks?.[0]?.grade?.name}
      />
      <IntroMessage
        image={BrandHero}
        imgStyle={{ width: mobile ? '100%' : '45%' }}
      />
      <Contest
        media={
          <img
            style={{ marginLeft: '2em' }}
            src={Media}
            alt='Konkursy z Panem Tablet'
          ></img>
        }
      />
      <div className={s.divider}>
        <Divider height={'2px'} backgroundColor={'#89939E'} />
      </div>
      <ChildrenWorks
        params={{
          school: params.schoolId,
          grade: params.gradeId,
        }}
        imgStyle={{ width: '100%', height: '172px' }}
        loggedIn={loggedIn}
      />
      <SocialMedia />
    </motion.div>
  );
};

export default GroupPage;
